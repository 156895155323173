import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: "60rem",
    margin: "0 auto",
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  secondRow: {
    paddingTop: "2rem",
  },
  item: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    height: "2.5rem",

    "& img": {
      height: "100%",
      width: '200px'
    },
  },
  image: {
    margin: "auto",
  },
  mobileContainer: {
    padding: "2.5rem 1.5rem",
    borderTop: "1px solid #235CFF",
    borderBottom: "1px solid #235CFF",
  },
}));
